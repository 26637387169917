<script>
	import { base } from "$app/paths";
</script>

<div class="h-screen">
	<div class="flex h-full">
		<div class="mx-auto my-auto flex w-3/5 flex-col items-center gap-y-8 md:w-2/5">
			<img src={`${base}/logo.svg`} alt="Edible logo" />
			<div class="mx-2">
				<h1 class="text-center text-xl text-gray-700">
					Allergen and diet friendly menus coming soon
				</h1>
			</div>
			<a
				href="https://whatisedible.com"
				class="block rounded-md bg-cherry p-3 font-semibold text-white"
			>
				What is Edible?
			</a>
		</div>
	</div>
</div>
